import React from "react"
import { Link } from "gatsby"
// import PropTypes from "prop-types"
import { signup } from '../../actions/AuthAction'
import { setAuthToken } from '../../actions/_auth'

class Signup extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      submitting:     false,
      email:          '',
      password:       '',
      error_message:  '',
    }
  }

  render() {
    return(
      <div className="auth-wrapper">
        <h3>REGISTER</h3>
        {this.errorMessage()}
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <label htmlFor="email">Your Email Address</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={this.state.email}
              onChange={this.onChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              autoComplete="new-password"
              id="password"
              name="password"
              value={this.state.password}
              onChange={this.onChange}
            />
          </div>
          <div className="form-group text-center">
            <button
              className="btn btn-lg btn-primary btn-normal"
              disabled={this.state.submitting}
            >REGISTER</button>
          </div>
          <div className="form-group text-center">
            <Link to="/auth/signin">No Account Yet, click here to Register</Link>
          </div>
        </form>
      </div>
    )
  }

  errorMessage = () => {
    if(this.state.error_message && this.state.error_message !== '') {
      return(
        <div className="alert alert-danger" role="alert">
          {this.state.error_message}
        </div>
      )
    }
  }

  onChange = (e) => {
    var new_state = {...this.state}
    new_state[e.target.name] = e.target.value
    this.setState(new_state)
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.setState({
      submitting: true,
      error_message: '',
    })
    var data = {
      email:    this.state.email,
      password: this.state.password
    }

    signup(data, {
      success:  this.onSuccess,
      error:    this.onError
    })
  }

  onSuccess = (res) => {
    this.setState({
      submitting: false,
      error_message: ''
    })
    setAuthToken(res.token)
    window.location = "/app"
  }

  onError = (message) => {
    this.setState({
      submitting: false,
      error_message: message
    })
  }

}

Signup.propTypes = {}
export default Signup
